// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// App
import logo from "images/gf365-logo.svg";
import NewsletterSignup from "components/ui/NewsletterSignup";
import {
  TERMS_OF_SERVICE_ROUTE,
  PRIVACY_POLICY_ROUTE,
  COOKIE_POLICY_ROUTE,
  CLUB_DIR_ROUTE,
  ABOUT_ROUTE
} from "common/RoutesHelper";

function Footer(props) {
  const { t } = useTranslation();
  const { className = "" } = props;

  const today = new Date();
  const year = today.getFullYear();

  return (
    <footer className={`Footer ${className}`}>
      <div className="container restricted-width-xl restricted-width-xs">
        <div className="row align-items-center py-5">
          <div className="col-12 col-md-4 col-lg-3 mb-3 mb-md-0">
            <img src={logo} alt="Greenfee365" className="mb-2" />
            <small className="d-block text-muted">
              ©{year} Greenfee365 Europe AB.
              <br />
              All Rights Reserved
            </small>
          </div>
          <div className="col-12 col-md-4 col-lg-2">
            <ul className="pl-0 m-0">
              <li>
                <Link to={ABOUT_ROUTE.url()}>{t("contact_us")}</Link>
              </li>
              <li>
                <a
                  href="https://greenfee365help.zendesk.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("help_center")}
                </a>
              </li>
              <li>
                <Link to={CLUB_DIR_ROUTE.url()}>{t("club_directory")}</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-4 col-lg-2">
            <ul className="pl-0 m-0">
              <li>
                <Link to={TERMS_OF_SERVICE_ROUTE.url()}>
                  {t("terms_of_service")}
                </Link>
              </li>
              <li>
                <Link to={PRIVACY_POLICY_ROUTE.url()}>
                  {t("privacy_policy")}
                </Link>
              </li>
              <li>
                <Link to={COOKIE_POLICY_ROUTE.url()}>{t("cookie_policy")}</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-6 col-lg-3 mt-4 mt-md-5 mt-lg-0">
            <NewsletterSignup />
          </div>
          <div className="col-12 col-md-3 col-lg-2 mt-4 mt-md-5 mt-lg-0">
            <a
              href="https://www.linkedin.com/company/greenfee365/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faLinkedinIn}
                title={t("linkedin_link_text")}
                size="lg"
                className="mr-3"
              />
            </a>
            <a
              href="https://www.facebook.com/greenfee365/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                title={t("facebook_link_text")}
                size="lg"
                className="mr-3"
              />
            </a>
            <a
              href="https://www.instagram.com/greenfee365/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                title={t("instagram_link_text")}
                size="lg"
              />
            </a>
          </div>
          <div className="col-12 mt-5 Footer__credit-cards">
            <ul className="pl-0 mb-0">
              <li className="pw pw-visa" />
              <li className="pw pw-mastercard" />
              <li className="pw pw-american-express" />
              <li className="pw pw-discover" />
              <li className="pw pw-diners" />
              <li className="pw pw-jcb" />
              <li className="pw pw-unionpay" />
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

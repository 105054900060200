export default [
  {
    language: "en-GB",
    label: "English",
    languageShort: "en",
    country: "gb"
  },
  {
    language: "es-ES",
    label: "Español",
    languageShort: "es",
    country: "es"
  },
  {
    language: "sv-SE",
    label: "Svenska",
    languageShort: "sv",
    country: "se"
  },
  {
    language: "de-DE",
    label: "Deutsch",
    languageShort: "de",
    country: "de"
  },
  {
    language: "da-DK",
    label: "Dansk",
    languageShort: "da",
    country: "dk"
  },
  {
    language: "fi-FI",
    label: "Suomi",
    languageShort: "fi",
    country: "fi"
  },
  {
    language: "fr-FR",
    label: "Français",
    languageShort: "fr",
    country: "fr"
  },
  {
    language: "it-IT",
    label: "Italiano",
    languageShort: "it",
    country: "it"
  },
  {
    language: "nl-NL",
    label: "Nederlands",
    languageShort: "nl",
    country: "nl"
  },
  {
    language: "nb-NO",
    label: "Norsk",
    languageShort: "nb",
    country: "no"
  },
  {
    language: "ru-RU",
    label: "русский",
    languageShort: "ru",
    country: "ru"
  }
];

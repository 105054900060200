// Vendor
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import detector from "config/detector";

// App
import enGB from "locales/en-GB.json";
import esES from "locales/es-ES.json";
import svSE from "locales/sv-SE.json";
import deDE from "locales/de-DE.json";
import daDK from "locales/da-DK.json";
import fiFI from "locales/fi-FI.json";
import itIT from "locales/it-IT.json";
import frFR from "locales/fr-FR.json";
import nlNL from "locales/nl-NL.json";
import nbNO from "locales/nb-NO.json";
import ruRU from "locales/ru-RU.json";

const languageDetector = new LanguageDetector();
languageDetector.addDetector(detector);

const initOptions = {
  debug: false, // change to true to find missing translations
  ns: ["gf365"],
  defaultNS: "gf365",
  fallbackLng: "en-GB",
  whitelist: [
    "da-DK",
    "de-DE",
    "en-GB",
    "es-ES",
    "fi-FI",
    "fr-FR",
    "it-IT",
    "nb-NO",
    "nl-NL",
    "ru-RU",
    "sv-SE"
  ],
  detection: {
    order: ["customPath", "cookie", "localStorage", "navigator", "htmlTag"]
  },
  interpolation: { escapeValue: false },
  react: {
    wait: false,
    withRef: false,
    nsMode: "default"
  },
  resources: {
    "da-DK": { gf365: daDK },
    "de-DE": { gf365: deDE },
    "en-GB": { gf365: enGB },
    "es-ES": { gf365: esES },
    "fi-FI": { gf365: fiFI },
    "fr-FR": { gf365: frFR },
    "it-IT": { gf365: itIT },
    "nb-NO": { gf365: nbNO },
    "nl-NL": { gf365: nlNL },
    "ru-RU": { gf365: ruRU },
    "sv-SE": { gf365: svSE }
  },
  saveMissing: false,
  parseMissingKeyHandler: function(key) {
    console.log(key);
  }
};

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init(initOptions);

//console.log(i18next);
export default i18next;

import gql from "graphql-tag";

export const BOOKING_CANCELLATION_MUTATION = gql`
  mutation BookingCancellationMutation($input: CancelTeeTimeInput!) {
    cancellation(input: $input) {
      success
      message
    }
  }
`;

export const CHECKOUT_MUTATION = gql`
  mutation CheckoutMutation($input: CheckoutInput!) {
    checkout(input: $input) {
      success
      message
      orderId
    }
  }
`;

export const EMAIL_VERIFICATION_MUTATION = gql`
  mutation EmailVerificationMutation(
    $email: String!
    $emailConfirmToken: String!
  ) {
    confirmEmail(email: $email, emailConfirmToken: $emailConfirmToken) {
      token
    }
  }
`;

export const NEWSLETTER_SIGNUP_MUTATION = gql`
  mutation NewsletterSignupMutation($email: String!) {
    newsletterSignup(email: $email)
  }
`;

export const PASSWORD_RESET_MUTATION = gql`
  mutation PasswordResetMutation(
    $email: String!
    $password: String!
    $resetToken: String!
  ) {
    passwordReset(email: $email, password: $password, resetToken: $resetToken) {
      _id
    }
  }
`;

export const SIGNIN_MUTATION = gql`
  mutation SigninMutation($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      token
      user {
        email
        firstName
        language
        lastName
        newsletter
      }
    }
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation SignupMutation($input: SignupInput!) {
    signup(input: $input) {
      user {
        _id
        email
        emailConfirmed
      }
      token
    }
  }
`;

export const TRIGGER_PASSWORD_RESET_MUTATION = gql`
  mutation TriggerPasswordResetMutation($email: String!) {
    triggerPasswordReset(email: $email) {
      ok
    }
  }
`;

export const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation UpdateUserProfileMutation($input: UserProfileInput!) {
    updateUserProfile(input: $input) {
      firstName
      lastName
      language
      newsletter
    }
  }
`;

// Vendor
import { AvForm } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

// App
import EventEmitterClient from "common/EventEmitterClient";
import { SIGNUP_MUTATION, NEWSLETTER_SIGNUP_MUTATION } from "common/Mutations";
import {
  CustomAlert,
  CheckoutNewsletter,
  FormLogo,
  InputFirstName,
  InputLastName,
  InputEmail,
  InputPassword,
  InputPasswordRepeat,
  SignupAgreement
} from "components/ui/FunctionalComponents";

function SignupForm(props) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [newsletter, setNewsletter] = useState(true);
  const [error, setError] = useState(false);

  const [signup, { data: success }] = useMutation(SIGNUP_MUTATION, {
    onCompleted: handleSignupCompleted,
    onError: () => setError(true)
  });
  const [newsletterSignup] = useMutation(NEWSLETTER_SIGNUP_MUTATION);

  const Success = () => (
    <div className="text-center">
      <p className="text-success mt-3 mb-2">{t("account_created_text")}</p>
      <p>{t("log_in_instructions_text")}</p>
      <Button color="primary" block onClick={() => props.onClose()}>
        {t("close")}
      </Button>
    </div>
  );

  return (
    <Modal className="SignupForm auth-form" toggle={toggle} isOpen>
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <FormLogo />
        {!success && (
          <AvForm onValidSubmit={handleValidSubmit} model={{ newsletter }}>
            <p className="key-figure text-muted text-center mt-3">
              {t("create_an_account")}
            </p>
            {error && <CustomAlert text={t("email_taken_text")} />}

            <InputFirstName />
            <InputLastName />
            <InputEmail onChange={() => setError(false)} />
            <InputPassword name="signupPassword" />
            <InputPasswordRepeat
              name="signupPasswordRepeat"
              match="signupPassword"
            />

            <SignupAgreement />
            <CheckoutNewsletter />

            <Button
              className="mb-4"
              color="secondary"
              block
              size="lg"
              type="submit"
            >
              {t("create")}
            </Button>

            <hr />
            <p className="d-flex align-items-center justify-content-center mb-0">
              <span>{t("already_have_an_account")}</span>{" "}
              <Button
                className="p-0 ml-2"
                color="link"
                onClick={() => {
                  props.onClose();
                  EventEmitterClient.emit("LOGIN_SHOW");
                }}
              >
                {t("log_in")}
              </Button>
            </p>
          </AvForm>
        )}
        {success && <Success />}
      </ModalBody>
    </Modal>
  );

  function handleSignupCompleted() {
    if (newsletter) newsletterSignup({ variables: { email } });
  }

  async function handleValidSubmit(event, values) {
    const { fname, lname, signupPassword, newsletter } = values;
    const email = values.email.toLowerCase();

    setError(false);
    setNewsletter(newsletter);
    setEmail(email);

    signup({
      variables: {
        input: {
          email,
          password: signupPassword,
          firstName: fname,
          lastName: lname,
          newsletter
        }
      }
    });
  }

  function toggle() {
    props.onClose();
    setError(false);
  }
}

SignupForm.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default SignupForm;

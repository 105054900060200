import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import Auth from "common/Auth";
import LocaleHelper from "common/LocaleHelper";

const api_url =
  process.env.REACT_APP_GOLFERS_BACKEND_URL || "http://localhost:4000";

const httpLink = new HttpLink({
  uri: api_url
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Auth.getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      language: LocaleHelper.getLocale().language
    }
  };
});

const link = ApolloLink.from([
  onError(catchAuthErr),
  // Merge the ApolloLink with HttpLink
  // concat should not be mistaken for Array.prototype.concat()
  authLink.concat(httpLink)
]);

function catchAuthErr({ graphQLErrors }) {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.extensions.code === "UNAUTHENTICATED") {
        Auth.logout(false);
      }
    }
  }
}

class ApolloClientProvider {
  constructor() {
    this.client = new ApolloClient({
      link,
      cache: new InMemoryCache()
    });
  }
}

export default new ApolloClientProvider();

// Vendor
import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";

// App
import Emitter from "common/EventEmitterClient";
import Routes from "components/app/Routes";
import ScrollToTop from "components/ui/ScrollToTop";
import SystemAlert from "components/ui/SystemAlert";
import { hasLocalStorage, hasSessionStorage } from "common/StorageHelper";
import { useTranslation } from "react-i18next";
import {
  StorageDisabledMessage,
  CookiePolicyMessage
} from "components/ui/StatusMessages";

function Body() {
  const { t } = useTranslation();
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    if (!hasLocalStorage || !hasSessionStorage) {
      addAlert({
        color: "warning",
        message: <StorageDisabledMessage className="d-inline mb-0" />
      });
    }

    Emitter.on("SYSTEM_ALERT", addAlert);
    Emitter.on("REMOVE_ALERTS", closeAlerts);

    return () => {
      Emitter.on("SYSTEM_ALERT", addAlert);
      Emitter.on("REMOVE_ALERTS", closeAlerts);
    };
  }, []);

  return (
    <div className="Body">
      {alerts.map((alert, index) => (
        <SystemAlert
          key={index}
          color={alert.color}
          message={alert.message}
          dismissable={alert.dismissable}
        />
      ))}
      <CookieConsent
        containerClasses="d-flex align-items-center justify-content-between p-4"
        buttonText={t("i_understand")}
        disableStyles
      >
        <CookiePolicyMessage />
      </CookieConsent>
      <ScrollToTop>
        <Routes />
      </ScrollToTop>
    </div>
  );

  function addAlert(alert) {
    if (alert.closeOthers) setAlerts([alert]);
    else setAlerts(prev => [...prev, alert]);

    // Scroll up to top so user can see the alert
    window.scroll(0, 0);
  }

  function closeAlerts() {
    setAlerts([]);
  }
}

export default Body;

import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { GOLF_CLUB_ROUTE } from "common/RoutesHelper";
import { useTranslation, Trans } from "react-i18next";
import golfClubIcon from "images/amenities/practice.svg";
import SVG from "react-inlinesvg";

export const CityHit = ({ hit, onClick }) => (
  <Fragment>
    <div className="react-autosuggest__suggestion__icon-container">
      <FontAwesomeIcon icon="map-marker-alt" />
    </div>
    <div>
      <Link to="/search" onClick={onClick}>
        {hit.city}
      </Link>
      <p>
        {hit.state}, {hit.country}
      </p>
    </div>
  </Fragment>
);

export const AreaHit = ({ hit, onClick }) => (
  <Fragment>
    <div className="react-autosuggest__suggestion__icon-container">
      <FontAwesomeIcon icon="map-marker-alt" />
    </div>
    <div>
      <Link to="/search" onClick={onClick}>
        {hit.area}
      </Link>
      <p>{hit.country}</p>
    </div>
  </Fragment>
);

export const CountryHit = ({ hit, onClick }) => (
  <Fragment>
    <div className="react-autosuggest__suggestion__icon-container">
      <FontAwesomeIcon icon="globe" />
    </div>
    <div>
      <Link to="/search" onClick={onClick}>
        {hit.country}
      </Link>
    </div>
  </Fragment>
);

export const GolfCourseHit = ({ hit, onClick }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="react-autosuggest__suggestion__icon-container">
        <SVG title={t("golf_club")} className="isvg" src={golfClubIcon} />
      </div>
      <div>
        <Link to={GOLF_CLUB_ROUTE.url(hit.slug)} onClick={onClick}>
          {hit.name}
        </Link>
        <p>
          {hit.city}, {hit.state}, {hit.country}
        </p>
      </div>
    </Fragment>
  );
};

export const NearMe = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <li className="react-autosuggest__suggestion my-2">
      <div className="react-autosuggest__suggestion__icon-container mt-0">
        <FontAwesomeIcon icon="map-marker-alt" />
      </div>
      <Link to="/search" className="flex-grow-1" onClick={onClick}>
        <span className="d-inline-block ml-1">{t("near_me")}</span>
      </Link>
    </li>
  );
};

export const AllCourses = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <li className="react-autosuggest__suggestion my-2">
      <div className="react-autosuggest__suggestion__icon-container mt-0">
        <SVG title={t("golf_club")} className="isvg" src={golfClubIcon} />
      </div>
      <Link to="/search" className="flex-grow-1" onClick={onClick}>
        <span className="d-inline-block ml-1">
          {t("show_all_golf_courses")}
        </span>
      </Link>
    </li>
  );
};

// TODO: Check if this gets translated on lang change
export const NoHits = ({ query }) => (
  <li className="react-autosuggest__suggestion no-hits my-2 text-black-50">
    <span>
      <Trans i18nKey="no_results">
        No search results for <strong>{{ query }}</strong>
      </Trans>
    </span>
  </li>
);

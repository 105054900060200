// Vendor
import React, { lazy, Suspense } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

// App
import {
  SEARCH_ROUTE,
  GOLF_CLUB_ROUTE,
  CHECKOUT_ROUTE,
  CLUB_DIR_ROUTE,
  ORDER_CONFIRMATION_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  COOKIE_POLICY_ROUTE,
  PRIVACY_POLICY_ROUTE,
  PASSWORD_RESET_ROUTE,
  VERIFY_EMAIL_ROUTE,
  START_ROUTE,
  USER_BOOKINGS_ROUTE,
  USER_NOTIFICATIONS_ROUTE,
  USER_PROFILE_ROUTE,
  ABOUT_ROUTE,
  GOLF_AREA_ROUTE
} from "common/RoutesHelper";
import Loader from "components/ui/Loader";

// Lazy loading components
const Start = lazy(() =>
  import(/* webpackChunkName: "start" */ "components/start/Start")
);
const Search = lazy(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "search" */ "components/search/Search"
  )
);
const Club = lazy(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "club" */ "components/club/Club"
  )
);
const Checkout = lazy(() =>
  import(/* webpackChunkName: "checkout" */ "components/checkout/Checkout")
);
const Order = lazy(() =>
  import(/* webpackChunkName: "order" */ "components/order/Order")
);
const PasswordReset = lazy(() =>
  import(
    /* webpackChunkName: "password-reset" */ "components/password-reset/PasswordReset"
  )
);
const VerifyEmail = lazy(() =>
  import(
    /* webpackChunkName: "verify-email" */ "components/verify-email/VerifyEmail"
  )
);
const UserBookings = lazy(() =>
  import(/* webpackChunkName: "user-bookings" */ "components/user/UserBookings")
);
const UserSettings = lazy(() =>
  import(/* webpackChunkName: "user-settings" */ "components/user/UserSettings")
);
const NoMatch = lazy(() =>
  import(/* webpackChunkName: "no-match" */ "components/no-match/NoMatch")
);
const ClubDir = lazy(() =>
  import(/* webpackChunkName: "club-dir" */ "components/club-directory/ClubDir")
);
const CompanyInfo = lazy(() =>
  import(/* webpackChunkName: "company-info" */ "components/info/CompanyInfo")
);

const GolfAreaFactory = lazy(() =>
  import(
    /* webpackChunkName: "golf-area" */ "components/golf-area/GolfAreaFactory"
  )
);

function Routes() {
  return (
    <Suspense fallback={<Loader fullscreen />}>
      <Switch>
        <Route path={ABOUT_ROUTE.path}>
          <CompanyInfo />
        </Route>
        <Route path={CHECKOUT_ROUTE.path}>
          <Checkout />
        </Route>
        <Route path={COOKIE_POLICY_ROUTE.path}>
          <CompanyInfo />
        </Route>
        <Route path={CLUB_DIR_ROUTE.path}>
          <ClubDir />
        </Route>
        <Route path={GOLF_AREA_ROUTE.path}>
          {" "}
          <GolfAreaFactory />
        </Route>
        <Route path={GOLF_CLUB_ROUTE.path}>
          <Club />
        </Route>
        <Route path={ORDER_CONFIRMATION_ROUTE.path}>
          <Order />
        </Route>
        <Route path={PASSWORD_RESET_ROUTE.path}>
          <PasswordReset />
        </Route>
        <Route path={PRIVACY_POLICY_ROUTE.path}>
          <CompanyInfo />
        </Route>
        <Route path={SEARCH_ROUTE.path}>
          <Search />
        </Route>
        <Route path={START_ROUTE.path} exact>
          <Start />
        </Route>
        <Route path={TERMS_OF_SERVICE_ROUTE.path}>
          <CompanyInfo />
        </Route>
        <Route path={USER_BOOKINGS_ROUTE.path}>
          <UserBookings />
        </Route>
        <Route path={USER_PROFILE_ROUTE.path}>
          <UserSettings />
        </Route>
        <Route path={USER_NOTIFICATIONS_ROUTE.path}>
          <UserSettings />
        </Route>
        <Route path={VERIFY_EMAIL_ROUTE.path}>
          <VerifyEmail />
        </Route>
        <Route>
          <NoMatch />
        </Route>
      </Switch>
    </Suspense>
  );
}

export default Routes;

// Vendor
import PropTypes from "prop-types";
import React from "react";
import { InstantSearch, Configure, Index } from "react-instantsearch-dom";

// App
import Algolia from "common/Algolia";
import AutoSuggestWrapper from "components/ui/search/AutoSuggestWrapper";

function AutoCompleteSearch(props) {
  return (
    <div className={`AutoCompleteSearch ${props.className}`}>
      <InstantSearch
        appId={Algolia.appId}
        apiKey={Algolia.apiKey}
        indexName={Algolia.areasIndex.value}
      >
        <Configure filters="activated:true" hitsPerPage={props.numHits} />
        <AutoSuggestWrapper {...props} />
        <Index indexName={Algolia.citiesIndex.value} />
        <Index indexName={Algolia.clubsIndex.value} />
        <Index indexName={Algolia.countriesIndex.value} />
      </InstantSearch>
    </div>
  );
}

AutoCompleteSearch.propTypes = {
  numHits: PropTypes.number,
  autoFocus: PropTypes.bool.isRequired
};

AutoCompleteSearch.defaultProps = {
  className: "",
  numHits: 5,
  autoFocus: false
};

export default AutoCompleteSearch;

// Vendor
import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Navbar, NavItem } from "reactstrap";
import { useTranslation } from "react-i18next";

// App
import Emitter from "common/EventEmitterClient";
import logo from "images/gf365-logo.svg";
import { CHECKOUT_ROUTE, START_ROUTE } from "common/RoutesHelper";
import AutoCompleteSearch from "components/ui/search/AutoCompleteSearch";
import LanguageDropdown from "components/ui/LanguageDropdown";
import {
  AuthMenuLoggedIn,
  AuthMenuLoggedOut,
  CartIcon
} from "components/ui/FunctionalComponents";
import MobileMenu from "components/app/MobileMenu";
import MobileMenuToggler from "components/app/MobileMenuToggle";
import useUserStatus from "hooks/useUserStatus";
import useCartCount from "hooks/useCartCount";

function Header() {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const isLoggedIn = useUserStatus();
  const cartCount = useCartCount();

  useEffect(() => {
    Emitter.on("SEARCH_FOCUS", handleSearchFocus);
    Emitter.on("SEARCH_BLUR", handleSearchBlur);

    return () => {
      Emitter.removeEventListener("SEARCH_FOCUS", handleSearchFocus);
      Emitter.removeEventListener("SEARCH_BLUR", handleSearchBlur);
    };
  }, []);

  return (
    <div className="Header position-relative">
      <Navbar className="d-flex" light expand="md">
        <Link
          to={START_ROUTE.url()}
          className="navbar-brand mr-md-3 mr-2 d-md-inline-block"
        >
          <img src={logo} alt="Greenfee365 logo" />
        </Link>

        <div
          className={`Header__search flex-grow-1 ${
            showControls ? "" : "Header__search--focus"
          }`}
          style={{ maxWidth: 500 }}
        >
          <AutoCompleteSearch />
        </div>

        <div className="d-md-none navbar-nav ml-auto flex-row">
          <NavItem>
            <Link
              to={CHECKOUT_ROUTE.url()}
              className="nav-link pl-3 pr-2 text-body"
            >
              <CartIcon theme="dark" count={cartCount} />
            </Link>
          </NavItem>

          <MobileMenuToggler
            open={menuOpen}
            onChange={value => setMenuOpen(value)}
          />
        </div>

        <div className="d-none d-md-flex navbar-nav ml-auto">
          <NavItem>
            <Link to={CHECKOUT_ROUTE.url()} className="nav-link">
              <CartIcon theme="dark" count={cartCount} />
              <span className="ml-1">{t("checkout")}</span>
            </Link>
          </NavItem>
          <LanguageDropdown alignRight inNavbar showLabel={false} />
          {isLoggedIn ? <AuthMenuLoggedIn /> : <AuthMenuLoggedOut />}
        </div>
      </Navbar>
      <MobileMenu onChange={() => setMenuOpen(false)} open={menuOpen} />
    </div>
  );

  function handleSearchFocus() {
    setShowControls(false);
  }

  function handleSearchBlur() {
    setShowControls(true);
  }
}

export default Header;

// Vendor
import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { StripeProvider } from "react-stripe-elements";
import { CloudinaryContext } from "cloudinary-react";

// App
//import registerServiceWorker from "./registerServiceWorker";
import { unregister } from "registerServiceWorker";
import Layout from "components/app/Layout";
import ApolloClientProvider from "common/ApolloClientProvider";
import initFontawesomeLib from "config/fontawesome";
import CloudinaryHelper from "common/CloudinaryHelper";
import ErrorBoundary from "components/ui/ErrorBoundary";
import "config/i18next";
import "styles/index.css";
import "payment-webfont/style.css";
import URLHelper from "common/URLHelper";

initFontawesomeLib();

Sentry.init({
  dsn: "https://20144ab3a54c411db86641df903a2267@sentry.io/1441000",
  environment: URLHelper.isLocalhost() ? "development" : "production",
  // we generate this error ourselves to use the error report dialog
  ignoreErrors: ["User Feedback: Broken Link"],
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false
    })
  ],
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === "ui.click") {
      const { event } = hint;
      const path = event.path || (event.composedPath && event.composedPath());
      if (path && Array.isArray(path)) {
        const excludes = ["body", "html", "#document"];
        let breadcrumbs = path
          .filter(node => typeof node.nodeName !== "undefined")
          .filter(node => !excludes.includes(node.nodeName.toLowerCase()))
          .reverse()
          .map(node => {
            const className =
              node.classList && node.classList.value
                ? node.classList.value.replace(/ {2}/g, " ").replace(/ /g, ".")
                : "";
            return `${node.localName}${className ? "." : ""}${className}`;
          });
        breadcrumb.message = breadcrumbs.join(" > ");
      }
    }
    return breadcrumb;
  },
  beforeSend: function(event, hint) {
    /**
     * decorate event with info coming from events with
     * unhandled promise rejections that reject with non-Error type
     * like primitive values (string, null, etc.)
     *
     * @see https://github.com/getsentry/sentry-javascript/issues/2210
     * */

    if (hint && hint.originalException instanceof Event) {
      const exception = hint.originalException;
      event.extra.isTrusted = exception.isTrusted;
      event.extra.detail = exception.detail;
      event.extra.type = exception.type;
      event.extra.message = exception.message;
    }
    return event;
  }
});

window.onerror = function(msg, url, lineNo, columnNo, error) {
  var string = msg.toLowerCase();

  // When an error occurs in a script, loaded from a different origin,
  // the details of the error are not reported to prevent leaking information.
  // Instead the error reported is simply "Script error."
  var substring = "script error";
  if (string.indexOf(substring) > -1) {
    // Google Translate API fires this event when it has problems replacing
    // or adding class names to DOM-nodes.
    // To prevent that this event propagates, reports to Sentry
    // and causes further errors, we return true
    if (googleTranslated) return true;
  }

  // returning false fires the default event handler (event propagates)
  return false;
};

let googleTranslated = false;

// Check for changes in DOM subtree
document.addEventListener(
  "DOMSubtreeModified",
  e => {
    if (e.target.tagName === "HTML" && window.google) {
      // page has been translated by Google
      if (e.target.className.match("translated")) {
        googleTranslated = true;
      }
    }
  },
  true
);

const apiKey =
  process.env.REACT_APP_GOLFERS_STRIPE_KEY ||
  "pk_test_76SawXJpZFbgHI6oTt8iZWlo";

ReactDOM.render(
  <BrowserRouter>
    <StripeProvider apiKey={apiKey}>
      <ApolloProvider client={ApolloClientProvider.client}>
        <CloudinaryContext
          cloudName="greenfee365"
          dpr={1}
          defaultImage={CloudinaryHelper.FALLBACK_IMG}
          className="h-100"
        >
          <ErrorBoundary
            context="index"
            global
            showErrorReportBtn
            showReloadBtn
          >
            <Layout />
          </ErrorBoundary>
        </CloudinaryContext>
      </ApolloProvider>
    </StripeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

//registerServiceWorker();
unregister();

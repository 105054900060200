import locales from "config/locales";

export default {
  name: "customPath",

  // the param "options" omitted
  lookup() {
    const path = window.location.pathname;
    const numSlashes = (path.match(/\//g) || []).length;
    let lang;

    // e.g. ".com/sv"
    if (numSlashes === 1) lang = path.substr(1);
    // e.g. ".com/sv/category"
    else {
      let stripFirstSlash = path.substr(1);
      lang = stripFirstSlash.substring(0, stripFirstSlash.indexOf("/"));
    }
    lang = lang.toLowerCase();
    const locale = locales.find(
      l => l.language.toLowerCase() === lang || l.languageShort === lang
    );

    return locale ? locale.language : null;
  }

  // cacheUserLanguage(lng, options) {
  // options -> are passed in options
  // lng -> current language, will be called after init and on changeLanguage
  // store it
  // }
};

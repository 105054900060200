import { matchPath } from "react-router-dom";
import LocaleHelper from "common/LocaleHelper";
import locales from "config/locales";

function getAppend(append, slug) {
  if (!append) return "";
  return slug === "/" ? append : `/${append}`;
}

function getLangPrefix(addLang) {
  const lang = LocaleHelper.getLocale().languageShort;
  if (!addLang || lang === "en") return "";
  return `/${lang}`;
}

function getUrl(slug, append, addLang = true) {
  return getLangPrefix(addLang) + slug + getAppend(append, slug);
}

export const routesMatch = (
  currentPath,
  destinationPath,
  exact = true,
  strict = false
) =>
  matchPath(currentPath, {
    path: destinationPath,
    exact,
    strict
  }) !== null;

export const isHome = path => {
  const slashes = (path.match(/\//g) || []).length;
  const hasTrail = path.lastIndexOf("/") < path.length - 1;
  let lang;

  if (routesMatch(path, START_ROUTE.path, true, true)) return true;

  // e.g. ".com/sv"
  if (slashes === 1) lang = path.substr(1);
  // e.g. ".com/sv/category"
  else if (slashes === 2 && !hasTrail) {
    let stripFirstSlash = path.substr(1);
    lang = stripFirstSlash.substring(0, stripFirstSlash.indexOf("/"));
  }

  if (lang) {
    lang = lang.toLowerCase();
    return locales.find(l => l.languageShort === lang) !== undefined;
  }

  return false;
};

const LANG_PREFIX = "/:lang(da|de|en|es|fi|fr|it|nl|nb|ru|sv)?/";

export const NOT_FOUND_ROUTE = {
  url: (append, addLang) => getUrl("/404", append, addLang),
  path: "404",
  name: "404"
};

export const ABOUT_ROUTE = {
  url: (append, addLang) => getUrl("/info/about", append, addLang),
  path: `${LANG_PREFIX}info/about`,
  name: "about"
};

export const CHECKOUT_ROUTE = {
  url: (append, addLang) => getUrl("/checkout", append, addLang),
  path: `${LANG_PREFIX}checkout`,
  name: "checkout"
};

export const CLUB_DIR_ROUTE = {
  url: (append, addLang) => getUrl("/club-directory", append, addLang),
  path: `${LANG_PREFIX}club-directory`,
  name: "club-directory"
};

export const COOKIE_POLICY_ROUTE = {
  url: (append, addLang) => getUrl("/info/cookie-policy", append, addLang),
  path: `${LANG_PREFIX}info/cookie-policy`,
  name: "cookie-policy"
};

export const GOLF_AREA_ROUTE = {
  url: (append, addLang) => getUrl("/golf-destination", append, addLang),
  path: `${LANG_PREFIX}golf-destination/:slug`,
  name: "golf-destination"
};

export const GOLF_CLUB_ROUTE = {
  url: (append, addLang) => getUrl("/golf-clubs", append, addLang),
  path: `${LANG_PREFIX}golf-clubs/:slug`,
  name: "golf-clubs"
};

export const ORDER_CONFIRMATION_ROUTE = {
  url: (append, addLang) => getUrl("/order-confirmation", append, addLang),
  path: `${LANG_PREFIX}order-confirmation/:orderId`,
  name: "order-confirmation"
};

export const PASSWORD_RESET_ROUTE = {
  url: (append, addLang) => getUrl("/password-reset", append, addLang),
  path: `${LANG_PREFIX}password-reset/:email/:resetToken`,
  name: "password-reset"
};

export const PRIVACY_POLICY_ROUTE = {
  url: (append, addLang) => getUrl("/info/privacy-policy", append, addLang),
  path: `${LANG_PREFIX}info/privacy-policy`,
  name: "privacy-policy"
};

export const SEARCH_ROUTE = {
  url: (append, addLang) => getUrl("/search", append, addLang),
  path: `${LANG_PREFIX}search`,
  name: "search"
};

export const START_ROUTE = {
  url: (append, addLang) => getUrl("/", append, addLang),
  path: `${LANG_PREFIX}`,
  name: "start"
};

export const TERMS_OF_SERVICE_ROUTE = {
  url: (append, addLang) => getUrl("/info/terms-of-service", append, addLang),
  path: `${LANG_PREFIX}info/terms-of-service`,
  name: "terms-of-service"
};

export const USER_BOOKINGS_ROUTE = {
  url: (append, addLang) => getUrl("/user/bookings", append, addLang),
  path: `${LANG_PREFIX}user/bookings`,
  name: "bookings"
};

export const USER_NOTIFICATIONS_ROUTE = {
  url: (append, addLang) => getUrl("/user/notifications", append, addLang),
  path: `${LANG_PREFIX}user/notifications`,
  name: "notifications"
};

export const USER_PROFILE_ROUTE = {
  url: (append, addLang) => getUrl("/user/profile", append, addLang),
  path: `${LANG_PREFIX}user/profile`,
  name: "profile"
};

export const VERIFY_EMAIL_ROUTE = {
  url: (append, addLang) => getUrl("/verify-email", append, addLang),
  path: `${LANG_PREFIX}verify-email/:email/:token`,
  name: "verify-email"
};

export const allRoutes = [
  ABOUT_ROUTE,
  CHECKOUT_ROUTE,
  CLUB_DIR_ROUTE,
  COOKIE_POLICY_ROUTE,
  GOLF_AREA_ROUTE,
  GOLF_CLUB_ROUTE,
  ORDER_CONFIRMATION_ROUTE,
  PASSWORD_RESET_ROUTE,
  PRIVACY_POLICY_ROUTE,
  SEARCH_ROUTE,
  START_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  USER_BOOKINGS_ROUTE,
  USER_PROFILE_ROUTE,
  VERIFY_EMAIL_ROUTE
];

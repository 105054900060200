export const hasSessionStorage = (function() {
  const mod = "gf365_sessionStorage_test";
  if (typeof Storage === "undefined") return false;
  try {
    sessionStorage.setItem(mod, mod);
    sessionStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
})();

export const hasLocalStorage = (function() {
  const mod = "gf_365_localStorage_test";
  if (typeof Storage === "undefined") return false;
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
})();

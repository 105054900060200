import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SystemAlert(props) {
  const { dismissable, color, message } = props;
  const [visible, setVisible] = useState(true);

  return (
    <Alert
      className="mb-0 position-sticky"
      closeClassName={dismissable ? "close" : "d-none"}
      color={color}
      isOpen={visible}
      toggle={dismissable ? handleDismiss : () => null}
    >
      {getIcon()}
      {message}
    </Alert>
  );

  function getIcon() {
    switch (color) {
      case "warning":
        return <FontAwesomeIcon icon="exclamation-triangle" className="mr-3" />;
      case "danger":
        return <FontAwesomeIcon icon="ban" className="mr-3" />;
      case "success":
        return <FontAwesomeIcon icon="check" className="mr-3" />;
      case "info":
        return <FontAwesomeIcon icon="info" className="mr-3" />;
      default:
        return null;
    }
  }

  function handleDismiss() {
    setVisible(false);
  }
}

SystemAlert.propTypes = {
  color: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  dismissable: PropTypes.bool
};

SystemAlert.defaultProps = {
  color: "info",
  dismissable: true
};

export default SystemAlert;

import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import logo from "images/gf365-logo.svg";
import { withRouter } from "react-router-dom";
import { hasLocalStorage } from "common/StorageHelper";

class ErrorBoundary extends Component {
  static propTypes = {
    text: PropTypes.string,
    global: PropTypes.bool,
    showErrorReportBtn: PropTypes.bool,
    showReloadBtn: PropTypes.bool,
    tryReload: PropTypes.bool
  };

  static defaultProps = {
    global: false,
    text:
      "An error occured in the application. Please try to reload the page or send us feedback if the problem persist.",
    showErrorReportBtn: false,
    showReloadBtn: false,
    tryReload: false
  };

  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
    this.reportError = this.reportError.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: true };
  }

  reportError(error, errorInfo) {
    const { context } = this.props;

    this.setState({ error });

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      if (context) scope.setTag("context", context);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
    if (hasLocalStorage) window.localStorage.removeItem("gf365-reloaded");
  }

  componentDidCatch(error, errorInfo) {
    if (this.props.tryReload === true && hasLocalStorage) {
      const hasReloaded = window.localStorage.getItem("gf365-reloaded");
      // prevent error reporting and reload page instead
      if (hasReloaded === null) {
        window.localStorage.setItem("gf365-reloaded", true);
        window.location.reload();
        return;
      }
    }

    this.reportError(error, errorInfo);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        error: false
      });
    }
  }

  render() {
    const { global, children } = this.props;

    const ErrorReportBtn = ({ color }) => (
      <Button
        color={color}
        className="d-inline-block"
        onClick={() => {
          Sentry.showReportDialog({ eventId: this.state.eventId });
        }}
      >
        Send feedback
      </Button>
    );

    const ReloadBtn = () => (
      <Button
        color="secondary"
        className="d-inline-block"
        onClick={() => {
          window.location.reload();
        }}
      >
        Reload the page
      </Button>
    );
    const GlobalError = () => (
      <>
        <img
          src={logo}
          alt="Greenfee365"
          style={{ width: 100, height: "auto" }}
        />
        <h1 className="mt-4">Whoops!</h1>
        <p>{this.props.text}</p>
        <div>
          {this.props.showReloadBtn && <ReloadBtn />}
          {this.props.showErrorReportBtn && <ErrorReportBtn color="link" />}
        </div>
      </>
    );

    const LocalError = () => (
      <>
        <FontAwesomeIcon
          icon="exclamation-triangle"
          className="text-danger d-block mx-auto mb-2 fa-2x"
        />
        <p className="mb-3">{this.props.text}</p>
        <div>
          {this.props.showReloadBtn && <ReloadBtn />}
          {this.props.showErrorReportBtn && <ErrorReportBtn color="link" />}
        </div>
      </>
    );

    if (this.state.error) {
      return (
        <div
          className={`my-6 container restricted-width-md d-flex flex-column text-center align-items-center ${
            global ? "" : "Body justify-content-center"
          }`}
        >
          {global ? <GlobalError /> : <LocalError />}
        </div>
      );
    } else return children;
  }
}

export default withRouter(ErrorBoundary);

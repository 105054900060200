class CookieHelper {
  static setCookie(cookieName, cookieValue, daysUntilExpire) {
    let cookie = cookieName + "=" + cookieValue + ";path=/";

    if (daysUntilExpire) {
      var d = new Date();
      d.setTime(d.getTime() + daysUntilExpire * 24 * 60 * 60 * 1000);
      cookie += ";expires=" + d.toUTCString();
    }

    document.cookie = cookie;
  }

  static getCookie(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];

      // trim cookie content of whitespace
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }

      // return the specific cookie
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }

    // if not found
    return null;
  }

  static deleteCookie(cookieName) {
    document.cookie = cookieName + "=; Max-Age=-99999999;";
  }
}

export default CookieHelper;

import { useState, useEffect } from "react";
import CartStorage from "common/CartStorage";
import Emitter from "common/EventEmitterClient";

export default function useCartCount() {
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    update();
  });

  useEffect(() => {
    Emitter.on("CART_UPDATE_SUCCESS", update);
    return () => {
      Emitter.removeEventListener("CART_UPDATE_SUCCESS", update);
    };
  }, []);

  function update() {
    setCartCount(CartStorage.count());
  }

  return cartCount;
}

import { useState, useEffect } from "react";
import Auth from "common/Auth";
import Emitter from "common/EventEmitterClient";

export default function useUserStatus() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    Emitter.on("LOGIN", update);
    Emitter.on("LOGOUT", update);

    return () => {
      Emitter.removeEventListener("LOGIN", update);
      Emitter.removeEventListener("LOGOUT", update);
    };
  }, []);

  function update() {
    setLoggedIn(Auth.isAuthenticated());
  }

  useEffect(() => {
    update();
  });

  return loggedIn;
}

import PropTypes from "prop-types";
import React from "react";
import { Button } from "reactstrap";

function TeeTimeOptionButtons(props) {
  const { minButton, maxButton, enabledValues, value, onClick } = props;
  let buttons = [];

  for (let index = minButton; index <= maxButton; index++) {
    buttons.push(
      <Button
        key={index}
        outline
        color="primary"
        className="mr-1 mb-1 btn-circle"
        active={value === index}
        disabled={!enabledValues.includes(index)}
        onClick={() => onClick(index)}
      >
        {index}
      </Button>
    );
  }

  return buttons;
}

TeeTimeOptionButtons.propTypes = {
  enabledValues: PropTypes.array.isRequired,
  value: PropTypes.number.isRequired,
  minButton: PropTypes.number.isRequired,
  maxButton: PropTypes.number.isRequired,
  onClick: PropTypes.func
};

export default TeeTimeOptionButtons;

// Vendor
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// App
import Auth from "common/Auth";
import LocaleHelper from "common/LocaleHelper";
import PathDetector from "config/detector";
import FlagIcon from "components/ui/FlagIcon";
import Emitter from "common/EventEmitterClient";
import LanguageList from "components/ui/LanguageList";

function LanguageDropdown(props) {
  function getInitialLanguage() {
    const user = Auth.getUser();
    const langFromPath = PathDetector.lookup();

    if (langFromPath) return langFromPath;
    else if (user) return user.language;

    return undefined;
  }

  const locale = LocaleHelper.getLocale(getInitialLanguage());
  const [label, setLabel] = useState(locale.label);
  const [country, setCountry] = useState(locale.country);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    Emitter.on("LOCALE_CHANGE", updateLocale);
    return () => {
      Emitter.removeEventListener("LOCALE_CHANGE", updateLocale);
    };
  });

  const {
    alignRight,
    inNavbar,
    direction,
    onChange,
    showLabel,
    toggleClasses,
    updateLangOnChange
  } = props;

  return (
    <Dropdown
      inNavbar={inNavbar}
      direction={direction}
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle
        nav
        style={{ whiteSpace: "nowrap" }}
        className={toggleClasses}
        caret
      >
        <FlagIcon code={country} />
        {showLabel && <span className="ml-2 d-inline-block">{label}</span>}
      </DropdownToggle>
      <DropdownMenu right={alignRight}>
        <LanguageList
          className="dropdown-item rounded-0"
          updateLangOnChange={updateLangOnChange}
          onChange={locale => {
            setCountry(locale.country);
            setLabel(locale.label);
            setDropdownOpen(false);
            onChange(locale.language);
          }}
        />
      </DropdownMenu>
    </Dropdown>
  );

  function toggle() {
    setDropdownOpen(prev => !prev);
  }

  function updateLocale(locale = LocaleHelper.getLocale()) {
    setLabel(locale.label);
    setCountry(locale.country);
  }
}

LanguageDropdown.propTypes = {
  alignRight: PropTypes.bool,
  direction: PropTypes.string,
  inNavbar: PropTypes.bool,
  onChange: PropTypes.func,
  showLabel: PropTypes.bool,
  toggleClasses: PropTypes.string,
  updateLangOnChange: PropTypes.bool
};

LanguageDropdown.defaultProps = {
  direction: "down",
  alignRight: false,
  inNavbar: false,
  onChange: () => null,
  showLabel: true,
  toggleClasses: ""
};

export default LanguageDropdown;

// Vendor
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useHistory } from "react-router-dom";

// App
import Emitter from "common/EventEmitterClient";
import LocaleHelper from "common/LocaleHelper";
import {
  routesMatch,
  PASSWORD_RESET_ROUTE,
  VERIFY_EMAIL_ROUTE,
  START_ROUTE,
  GOLF_CLUB_ROUTE,
  isHome
} from "common/RoutesHelper";
import Header from "components/app/Header";
import Body from "components/app/Body";
import Footer from "components/app/Footer";
import LoginForm from "components/ui/auth/LoginForm";
import SignupForm from "components/ui/auth/SignupForm";
import ForgotPasswordForm from "components/ui/auth/ForgotPasswordForm";
import { appendScript } from "common/Scripts";
import ErrorBoundary from "components/ui/ErrorBoundary";
import URLHelper from "common/URLHelper";
import { useTranslation } from "react-i18next";

function Layout() {
  const { t } = useTranslation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    // append external scripts to header
    if (!URLHelper.isLocalhost()) {
      loadZendesk();
      loadRecaptcha();
    }

    Emitter.on("FORGOT_PASSWORD_SHOW", handleShowForgotPassword);
    Emitter.on("LOCALE_CHANGE", handleLocaleChange);
    Emitter.on("LOGIN_SHOW", handleShowLogin);
    Emitter.on("LOGOUT", handleLogout);
    Emitter.on("MOBILE_MENU_CHANGE", setOverflow);
    Emitter.on("SIGNUP_SHOW", handleShowSignup);

    return () => {
      Emitter.removeEventListener(
        "FORGOT_PASSWORD_SHOW",
        handleShowForgotPassword
      );
      Emitter.removeEventListener("LOCALE_CHANGE", handleLocaleChange);
      Emitter.removeEventListener("LOGIN_SHOW", handleShowLogin);
      Emitter.removeEventListener("LOGOUT", handleLogout);
      Emitter.removeEventListener("MOBILE_MENU_CHANGE", setOverflow);
      Emitter.removeEventListener("SIGNUP_SHOW", handleShowSignup);
    };
  }, []);

  // TODO: Use this solution when EventEmitterClient is removed
  // useEffect(() => {
  //   updateLocaleByUrl();
  // }, [location]);

  // helpers
  const path = location.pathname;
  const isPasswordReset = routesMatch(path, PASSWORD_RESET_ROUTE.path);
  const isVerifyEmail = routesMatch(path, VERIFY_EMAIL_ROUTE.path);
  const isStart = isHome(path);
  const isGolfCourse = routesMatch(path, GOLF_CLUB_ROUTE.path);
  const hideHeader = isPasswordReset || isVerifyEmail || isStart;
  const hideFooter = isPasswordReset || isVerifyEmail;
  const footerClassName = isGolfCourse ? "mt-0" : "mt-5";
  const { languageShort } = LocaleHelper.getLocale();

  return (
    <div
      className={`Layout ${
        mobileMenuOpen ? "overflow-hidden position-static" : ""
      }`}
    >
      <Helmet htmlAttributes={{ lang: languageShort }}>
        {!URLHelper.isProduction() && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        {LocaleHelper.getLocales().map((locale, i) => (
          <link
            key={i}
            rel="alternate"
            hrefLang={
              locale.language === LocaleHelper.defaultLang
                ? "x-default"
                : locale.language
            }
            href={URLHelper.getCurrentPageUrlByLang(locale.languageShort)}
          />
        ))}
      </Helmet>

      {showLogin && <LoginForm onClose={() => setShowLogin(false)} />}
      {showSignup && <SignupForm onClose={() => setShowSignup(false)} />}
      {showForgotPassword && (
        <ForgotPasswordForm onClose={() => setShowForgotPassword(false)} />
      )}

      {!hideHeader && <Header />}

      <ErrorBoundary
        context="layout"
        tryReload
        showErrorReportBtn
        showReloadBtn
      >
        <Body />
      </ErrorBoundary>

      {!hideFooter && <Footer className={footerClassName} />}
    </div>
  );

  function handleLocaleChange(locale) {
    // Get current url from document instead of hooks.
    // When this function is run, the hook have not yet changed
    // and therefore returns incorrect values, creating
    // inconsistent URLs
    const { pathname, search } = document.location;
    const currentUrl = `${pathname}${search}`;

    const newUrl = URLHelper.getNewPageUrlByLang(locale.languageShort);

    // go to new newUrl if not same as before
    if (newUrl !== currentUrl) history.push(newUrl);
  }

  function handleLogout(gotoStart) {
    if (!isHome(location.pathname) && gotoStart)
      history.push(START_ROUTE.url());
  }

  function handleShowForgotPassword() {
    setShowForgotPassword(true);
  }

  function handleShowLogin() {
    setShowLogin(true);
  }

  function handleShowSignup() {
    setShowSignup(true);
  }

  function loadRecaptcha() {
    appendScript(
      "https://www.google.com/recaptcha/api.js",
      "recaptcha-snippet",
      true,
      true
    );
  }

  function loadZendesk() {
    appendScript(
      "https://static.zdassets.com/ekr/snippet.js?key=0f903061-2745-4bde-a531-010cf94bc510",
      "ze-snippet",
      false,
      true
    )
      .then(() => {
        window.zE(() => window.zE.setLocale("en"));
      })
      .catch(() =>
        Emitter.emit("SYSTEM_ALERT", {
          color: "danger",
          message: t("customer_service_unavailable_text"),
          dismissable: true
        })
      );
  }

  function setOverflow(mobileMenuOpen) {
    //Set a timeout so mobile menu icon animation can finish
    setTimeout(() => {
      setMobileMenuOpen(mobileMenuOpen);
    }, 500);
  }

  // function updateLocaleByUrl() {
  //   let currentLang = LocaleHelper.getLocale().language;
  //   let newLang = PathDetector.lookup() || currentLang;

  //   // change lang if the url contains a lang string that differs from the current lang
  //   if (currentLang !== newLang) LocaleHelper.load(newLang);
  // }
}

export default Layout;

const trimSpaces = str => str.replace(/\s/g, "");

const matchesAll = (needles, haystack) => {
  let count = 0;
  needles.forEach(needle => {
    if (haystack.indexOf(needle) !== -1) {
      count++;
    }
  });
  return count === needles.length;
};

class MatchHelper{
  static isMatch(needle, haystack){
    let isMatch = false;
  
    haystack = haystack.toLowerCase();
    needle = needle.toLowerCase();

    let needles = needle.split(" ");

    let trimmedHaystack = trimSpaces(haystack);
    let trimmedNeedle = trimSpaces(needle);

    // check to see if each part of the query matches any hit
    if (matchesAll(needles, haystack)) {
      isMatch = true;
    }

    // If still no match, we compare with a trimmed query
    if (!isMatch) {
      isMatch = trimmedHaystack.indexOf(trimmedNeedle) !== -1;
    }

    // If still no match, we check if each part of the query matches any hit, with last char removed (typo)
    if (!isMatch && needle.length > 1) {
      let subNeedle = needle.substring(0, needle.length - 1);
      let subNeedles = subNeedle.split(" ");
      isMatch = matchesAll(subNeedles, haystack);
    }

    // If still no match, we check if each part of the query matches any hit, with last char removed (typo)
    if (!isMatch && needle.length > 1) {
      let subNeedle = trimmedNeedle.substring(0, trimmedNeedle.length - 1);
      let subNeedles = subNeedle.split(" ");
      isMatch = matchesAll(subNeedles, trimmedHaystack);
    }

    return isMatch;
  }
}

export default MatchHelper;
/**
 * Helper class for GoogleTagManager to track club related events
 */
export class ClubTracker {
  /**
   * Track visit to a club page
   * @param {Object} club Club object
   */
  clubPageView(club) {
    let ecomEvent = {
      event: "clubPageView",
      ecommerce: {
        detail: {
          products: [
            {
              name: club.name,
              id: club._id
            }
          ]
        }
      }
    };
    window.dataLayer.push(ecomEvent);
  }
}

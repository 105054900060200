import i18next from "i18next";

export const EMAIL_VALIDATOR = {
  required: {
    value: true,
    errorMessage: i18next.t("required")
  },
  email: {
    value: true,
    errorMessage: i18next.t("validation_email_text")
  }
};

export const NAME_VALIDATOR = {
  required: {
    value: true,
    errorMessage: i18next.t("required")
  },
  pattern: {
    value: "^[A-zÀ-ÿ- ]+$",
    errorMessage: i18next.t("validation_letters_text")
  },
  minLength: {
    value: 2,
    errorMessage: i18next.t("validation_min_length_text", { number: 2 })
  },
  maxLength: { value: 25 }
};

export const PASSWORD_VALIDATOR = {
  required: {
    value: true,
    errorMessage: i18next.t("required")
  },
  minLength: {
    value: 9,
    errorMessage: i18next.t("validation_min_length_text", { number: 9 })
  }
};

export const getRepeatPasswordValidator = matchInputName => {
  return {
    required: {
      value: true,
      errorMessage: i18next.t("required")
    },
    match: {
      value: matchInputName,
      errorMessage: i18next.t("did_not_match_password")
    }
  };
};

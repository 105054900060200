"use strict";
/**
 * react-app-polyfill
 * includes minimum requirements and commonly used language features used by Create React App projects.
 *
 * Promise (for async / await support)
 * window.fetch (a Promise-based way to make web requests in the browser)
 * Object.assign (a helper required for Object Spread, i.e. { ...a, ...b })
 * Symbol (a built-in object used by for...of syntax and friends)
 * Array.from (a built-in static method used by array spread, i.e. [...arr])
 *
 */
// Add fetch support

require("whatwg-fetch"); // This will emulate a full ES2015+ environment (no < Stage 4 proposals)


require("@babel/polyfill"); // Polyfill for intersection observer


require("intersection-observer");
import React from "react";
import { Button, NavItem } from "reactstrap";
import { PropTypes } from "prop-types";
import EventEmitterClient from "common/EventEmitterClient";

function MobileMenuToggler(props) {
  const { open, onChange, color } = props;
  return (
    <NavItem>
      <Button
        color="link"
        className={`mobile-menu-toggler nav-link px-2 ${
          open ? "mobile-menu-toggler--open" : ""
        } mobile-menu-toggler--${color}`}
        onClick={() => {
          const value = !open; // toggle current value
          EventEmitterClient.emit("MOBILE_MENU_CHANGE", value);
          onChange(value);
        }}
      >
        <span className="mobile-menu-toggler__icon">
          <span />
          <span />
          <span />
          <span />
        </span>
      </Button>
    </NavItem>
  );
}

MobileMenuToggler.propTypes = {
  color: PropTypes.oneOf(["white", "black"]).isRequired,
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

MobileMenuToggler.defaultProps = {
  color: "black",
  open: false,
  onChange: () => null
};

export default MobileMenuToggler;

/**
 * Helper class for GoogleTagManagerHelper
 * Manage checkout & cart related tracking events
 */
export class CheckoutTracker {
  constructor() {
    this.cartItems = [];
  }

  purchase(orderId) {
    let orderSum = 0;

    for (let item of this.cartItems) {
      orderSum += item.price;
    }

    window.dataLayer.push({
      event: "purchase",
      orderSum,
      orderId,
      ecommerce: {
        purchase: {
          actionField: {
            id: orderId,
            revenue: orderSum
          },
          products: this.cartItems
        }
      }
    });
    this.cartItems = [];
  }

  checkoutStart() {
    window.dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: "Checkout page" },
          products: this.cartItems
        }
      }
    });
  }

  /**
   * User has clicked the "Place order" button
   * @param {{ newsletter: boolean, guest: boolean}} options
   */
  checkoutAttempt(options = { newsletter: false, guest: true }) {
    let actionField = { step: "Place order click" };

    if (!options.guest) {
      actionField.option = "Logged in";
    } else if (options.newsletter) {
      actionField.option = "Newsletter";
    }

    window.dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField,
          products: this.cartItems
        }
      }
    });
  }

  removeFromCart(items) {
    for (const item of items) {
      this.cartItems.splice(this.cartItems.find(i => i.id === item.id), 1);
    }
  }

  addToCart(items) {
    this.cartItems = this.cartItems.concat(items);
  }
}

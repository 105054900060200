// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";

// App
import {
  START_ROUTE,
  USER_PROFILE_ROUTE,
  USER_BOOKINGS_ROUTE
} from "common/RoutesHelper";
import Auth from "common/Auth";
import Emitter from "common/EventEmitterClient";
import LanguageList from "components/ui/LanguageList";
import useUserStatus from "hooks/useUserStatus";

function MobileMenu(props) {
  const { t } = useTranslation();
  const [langMenuOpen, setLangMenuOpen] = useState(false);
  const loggedIn = useUserStatus();

  useEffect(() => {
    Emitter.on("MOBILE_MENU_CHANGE", closeLangMenu);
    return () => {
      Emitter.on("MOBILE_MENU_CHANGE", closeLangMenu);
    };
  }, []);

  const LanguageMenu = () => (
    <>
      <Button
        color="link"
        className="nav-link py-3"
        onClick={() => setLangMenuOpen(prev => !prev)}
      >
        {t("language")}
        <FontAwesomeIcon
          icon="angle-down"
          className={`ml-2 ${langMenuOpen ? "flip" : ""}`}
        />
      </Button>
      <ul
        className={`MobileMenu__lang-menu d-flex flex-wrap ${
          langMenuOpen ? "MobileMenu__lang-menu--open" : ""
        }`}
      >
        <div className="w-100">
          <hr className="mx-auto mt-0" />
        </div>
        <LanguageList
          className="nav-link p-2"
          onChange={() => {
            handleLinkClick();
            setLangMenuOpen(false);
          }}
        />
      </ul>
    </>
  );

  const { open } = props;

  return (
    <div className={`MobileMenu fixed-top ${open ? "MobileMenu--open" : ""}`}>
      <div className="d-flex justify-content-center p-4 pt-5 text-center">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link
              to={START_ROUTE.url()}
              onClick={handleLinkClick}
              className="nav-link py-3"
            >
              {t("home")}
            </Link>
          </li>
          {loggedIn && (
            <>
              <li className="nav-item">
                <Link
                  to={USER_BOOKINGS_ROUTE.url()}
                  onClick={handleLinkClick}
                  className="nav-link py-3"
                >
                  {t("my_bookings")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={USER_PROFILE_ROUTE.url()}
                  onClick={handleLinkClick}
                  className="nav-link py-3"
                >
                  {t("settings")}
                </Link>
              </li>
              <li className="nav-item">
                <LanguageMenu />
              </li>
              <li className="nav-item">
                <Link
                  to="/signup"
                  className="nav-link py-3"
                  onClick={e => {
                    e.preventDefault();
                    handleLinkClick();
                    Auth.logout();
                  }}
                >
                  {t("log_out")}
                </Link>
              </li>
            </>
          )}
          {!loggedIn && (
            <>
              <li className="nav-item">
                <Link
                  to="/login"
                  className="nav-link py-3"
                  onClick={e => {
                    e.preventDefault();
                    handleLinkClick();
                    Emitter.emit("LOGIN_SHOW");
                  }}
                >
                  {t("log_in")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/signup"
                  className="nav-link py-3"
                  onClick={e => {
                    e.preventDefault();
                    handleLinkClick();
                    Emitter.emit("SIGNUP_SHOW");
                  }}
                >
                  {t("sign_up")}
                </Link>
              </li>
              <li className="nav-item">
                <LanguageMenu />
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );

  function closeLangMenu() {
    setLangMenuOpen(false);
  }

  function handleLinkClick() {
    props.onChange();
    Emitter.emit("MOBILE_MENU_CHANGE", false);
  }
}

MobileMenu.propTypes = {
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool
};

MobileMenu.defaultProps = {
  onChange: () => null,
  open: false
};

export default MobileMenu;

// Vendor
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback
} from "availity-reactstrap-validation";
import React, { useState } from "react";
import { useMutation } from "react-apollo";
import Recaptcha from "react-recaptcha";
import { Button, Popover, PopoverBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import { NEWSLETTER_SIGNUP_MUTATION } from "common/Mutations";
import { sitekey } from "config/recaptcha";
import { useTranslation } from "react-i18next";

let input;

function NewsletterSignup() {
  const { t } = useTranslation();
  const [recaptcha, setRecaptcha] = useState(false);

  const [newsletterSignup, { data, error }] = useMutation(
    NEWSLETTER_SIGNUP_MUTATION
  );

  return (
    <div className="NewsletterSignup">
      <AvForm onValidSubmit={() => setRecaptcha(true)}>
        <AvGroup className="input-group mb-0">
          <AvInput
            name="newsletterEmail"
            id="newsletterEmail"
            type="email"
            ref={ref => (input = ref)}
            placeholder={t("newsletter")}
            style={{ borderRadius: "2rem 0 0 2rem" }}
            className="pl-3"
            required
          />
          <div className="input-group-append">
            <Button
              type="submit"
              color="primary"
              id="newsletter-submit"
              style={{ borderRadius: "0 2rem 2rem 0" }}
            >
              <FontAwesomeIcon icon="arrow-right" />
            </Button>
            <Popover
              placement="left"
              isOpen={recaptcha}
              target="newsletter-submit"
              toggle={toggleRecaptcha}
              className="p-2"
            >
              <PopoverBody>
                <Recaptcha
                  sitekey={sitekey}
                  size="normal"
                  render="explicit"
                  verifyCallback={verifyCallback}
                />
              </PopoverBody>
            </Popover>
          </div>
          <AvFeedback>{t("enter_valid_email")}</AvFeedback>
        </AvGroup>
      </AvForm>

      {data && data.newsletterSignup && (
        <p className="text-success mt-2 mb-0">
          {t("newsletter_signup_success")}
        </p>
      )}
      {error && (
        <p className="text-danger mt-2 mb-0">
          {t("newsletter_signup_failure")}
        </p>
      )}
    </div>
  );

  function toggleRecaptcha() {
    setRecaptcha(prev => !prev);
  }

  function verifyCallback() {
    newsletterSignup({
      variables: { email: input.value }
    });
    input.value = "";
    toggleRecaptcha();
  }
}

export default NewsletterSignup;

import qs from "qs";
import detector from "config/detector";
import LocaleHelper from "common/LocaleHelper";

class URLHelper {
  static getCurrentPageUrlByLang(lang) {
    const { origin, pathname } = document.location;
    const stripped = this.stripLang(pathname);
    if (lang === LocaleHelper.defaultLangShort) return `${origin}${stripped}`;
    return `${origin}/${lang}${stripped}`;
  }

  static getNewPageUrlByLang(lang) {
    const { pathname, search } = document.location;

    // the lang from the URL is the current
    const urlLang = detector.lookup();
    const newPath = urlLang ? pathname.substring(3) : pathname;

    // don't prepend for default lang
    if (lang === LocaleHelper.defaultLangShort) return `${newPath}${search}`;

    // prepend language
    return `/${lang}${newPath}${search}`;
  }

  static hasParam = (location, param, value) => {
    const urlParams = this.queryToState(location);
    return urlParams[param] ? urlParams[param] === value : false;
  };

  static hasAnyOfParams(location, props) {
    const urlParams = this.queryToState(location);
    let match = false;
    props.forEach(prop => {
      if (urlParams[prop]) match = true;
    });
    return match;
  }

  static isLocalhost() {
    return (
      window.location.origin.match(
        /^(http)+s?(:\/\/localhost)+|(http)+s?(:\/\/127.0.0.1)+/g
      ) !== null
    );
  }

  static isProduction() {
    return /^https?:\/\/(www+\.)?greenfee365\.com$/.test(
      window.location.origin
    );
  }

  static queryToState = location => qs.parse(location.search.slice(1));

  static queryFromState = state => `?${qs.stringify(state)}`;

  static stateToUrl = (path, state) =>
    state ? `${path}${this.queryFromState(state)}` : "";

  // this method is not intended for the default language
  static stripLang(path) {
    const language = detector.lookup();

    // return if no lang in url
    if (!language) return path;

    // start substring from index 3 (/sv[/]category)
    return path.length > 3 ? path.substring(3) : path;
  }
}

export default URLHelper;

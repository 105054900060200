// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { AvGroup, AvField, AvForm } from "availity-reactstrap-validation";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

// App
import { TRIGGER_PASSWORD_RESET_MUTATION } from "common/Mutations";
import { EMAIL_VALIDATOR } from "common/Validators";
import { CustomAlert, FormLogo } from "components/ui/FunctionalComponents";
import logo from "images/gf365-logo.svg";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-apollo";

function ForgotPasswordForm(props) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [requestResetEmail, { data: success }] = useMutation(
    TRIGGER_PASSWORD_RESET_MUTATION,
    {
      onCompleted: () => setError(false),
      onError: () => setError(true)
    }
  );

  const Success = () => (
    <div className="Success text-center">
      <p className="mt-3">
        {t("forgot_password_mail_sent_text")}
        <br />
        <span className="text-secondary">{email}</span>
      </p>
      <Button color="primary" block onClick={props.onClose}>
        {t("close")}
      </Button>
    </div>
  );

  return (
    <Modal className="ForgotPasswordForm auth-form" isOpen toggle={toggle}>
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <FormLogo src={logo} />
        {!success && (
          <AvForm onValidSubmit={handleValidSubmit}>
            <p className="key-figure text-muted text-center mt-3 mb-2">
              {t("forgot_password")}
            </p>
            <p>{t("forgot_password_instruction_text")}</p>
            {error && (
              <CustomAlert text={t("there_is_no_account_with_this_email")} />
            )}

            <AvGroup className="mb-3">
              <div className="icon-input-group icon-input-group-lg">
                <AvField
                  className="form-control-lg"
                  name="email"
                  autoComplete="username"
                  placeholder={t("email")}
                  value={email}
                  validate={EMAIL_VALIDATOR}
                  onChange={event => {
                    setError(false);
                    setEmail(event.target.value);
                  }}
                />
                <FontAwesomeIcon
                  focusable={false}
                  size="sm"
                  className="text-black-50"
                  icon="envelope"
                />
              </div>
            </AvGroup>

            <Button
              className="mb-4"
              color="secondary"
              block
              size="lg"
              type="submit"
            >
              {t("send")}
            </Button>
          </AvForm>
        )}
        {success && <Success />}
      </ModalBody>
    </Modal>
  );

  async function handleValidSubmit() {
    setError(false);
    requestResetEmail({ variables: { email } });
  }

  function toggle() {
    props.onClose();
    setError(false);
  }
}

ForgotPasswordForm.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ForgotPasswordForm;

// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { AvGroup, AvField, AvForm } from "availity-reactstrap-validation";

// App
import Auth from "common/Auth";
import EventEmitterClient from "common/EventEmitterClient";
import { EMAIL_VALIDATOR, PASSWORD_VALIDATOR } from "common/Validators";
import { FormLogo, CustomAlert } from "components/ui/FunctionalComponents";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function LoginForm(props) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState(undefined);
  const { showSignup, showForgotPassword, onClose } = props;

  // TODO: move to StatusMessages.js when in master
  const LocalStorageError = () => (
    <p className="d-inline mb-0">
      Login has been disabled for this device. Enable{" "}
      <a
        href="https://en.wikipedia.org/wiki/Web_storage"
        target="_blank"
        rel="noopener noreferrer"
      >
        Web Storage
      </a>{" "}
      or update your browser to log in.
    </p>
  );

  return (
    <Modal className="LoginForm auth-form" isOpen toggle={toggle}>
      <ModalHeader toggle={toggle} />
      <ModalBody>
        <AvForm onValidSubmit={handleValidSubmit}>
          <FormLogo />
          <p className="key-figure text-muted text-center mt-3">
            {t("log_in_to_greenfee365")}
          </p>
          {error && errorType === "LOCAL_STORAGE_NOT_SUPPORTED" && (
            <CustomAlert text={<LocalStorageError />} />
          )}
          {error && errorType === "LOGIN_FAILED" && (
            <CustomAlert text={t("log_in_failed_text")} />
          )}
          {error && errorType === "EMAIL_NOT_VERIFIED" && (
            <CustomAlert text={t("log_in_unverified_email_text")} />
          )}
          {error && errorType === "NETWORK_ERROR" && (
            <CustomAlert text={t("network_failure_text")} />
          )}

          <AvGroup className="mb-3">
            <div className="icon-input-group icon-input-group-lg">
              <AvField
                className="form-control-lg"
                name="loginEmail"
                autoComplete="username"
                placeholder={t("email")}
                validate={EMAIL_VALIDATOR}
              />
              <FontAwesomeIcon
                focusable={false}
                size="sm"
                className="text-black-50"
                icon="envelope"
              />
            </div>
          </AvGroup>

          <AvGroup className="mb-4">
            <div className="icon-input-group icon-input-group-lg">
              <AvField
                className="form-control-lg"
                type="password"
                name="loginPassword"
                autoComplete="current-password"
                placeholder={t("password")}
                validate={PASSWORD_VALIDATOR}
              />
              <FontAwesomeIcon
                focusable={false}
                size="sm"
                className="text-black-50"
                icon="lock"
              />
            </div>
          </AvGroup>

          <Button color="secondary" block size="lg" type="submit">
            {t("log_in")}
          </Button>

          {showForgotPassword && (
            <div className="text-center">
              <Button
                className="mt-3"
                color="link"
                onClick={() => {
                  onClose();
                  EventEmitterClient.emit("FORGOT_PASSWORD_SHOW");
                }}
              >
                {t("forgot_password")}
              </Button>
            </div>
          )}

          {showSignup && (
            <div>
              <hr />
              <p className="d-flex align-items-center justify-content-center mb-0">
                <span>{t("dont_have_an_account")}</span>{" "}
                <Button
                  color="link"
                  className="p-0 ml-2"
                  onClick={() => {
                    onClose();
                    EventEmitterClient.emit("SIGNUP_SHOW");
                  }}
                >
                  {t("sign_up")}
                </Button>
              </p>
            </div>
          )}
        </AvForm>
      </ModalBody>
    </Modal>
  );

  function handleLoginFail(error) {
    const { graphQLErrors } = error;
    const emailNotVerified =
      graphQLErrors &&
      graphQLErrors[0] &&
      graphQLErrors[0].message === "EMAIL_NOT_VERIFIED";
    let errorType;

    if (error.type === "LOCAL_STORAGE_NOT_SUPPORTED")
      errorType = "LOCAL_STORAGE_NOT_SUPPORTED";
    else if (error.networkError) errorType = "NETWORK_ERROR";
    else if (emailNotVerified) errorType = "EMAIL_NOT_VERIFIED";
    else errorType = "LOGIN_FAILED";

    setError(true);
    setErrorType(errorType);
  }

  async function handleValidSubmit(event, values) {
    setError(false);
    setErrorType(null);

    try {
      await Auth.login(values.loginEmail, values.loginPassword);
      props.onClose();
      return true;
    } catch (error) {
      handleLoginFail(error);
      return false;
    }
  }

  function toggle() {
    props.onClose();
    setError(false);
  }
}

LoginForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  showForgotPassword: PropTypes.bool,
  showSignup: PropTypes.bool
};

LoginForm.defaultProps = {
  showForgotPassword: true,
  showSignup: true
};

export default LoginForm;

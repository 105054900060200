// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import { useTranslation } from "react-i18next";
import { connectAutoComplete } from "react-instantsearch-dom";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "reactstrap";

// App
import Algolia from "common/Algolia";
import Emitter from "common/EventEmitterClient";
import MatchHelper from "common/MatchHelper";
import {
  routesMatch,
  SEARCH_ROUTE,
  GOLF_CLUB_ROUTE
} from "common/RoutesHelper";
import URLHelper from "common/URLHelper";
import {
  CityHit,
  AreaHit,
  CountryHit,
  GolfCourseHit,
  NearMe,
  AllCourses,
  NoHits
} from "components/ui/search/SearchHits";

const GEO_ATTRS = ["area", "city", "country", "query", "state"];
let searchContainerRef = React.createRef();
let input;
let hasKeyboardSelection = false;

function AutoSuggestWrapper(props) {
  const { t } = useTranslation();
  const [hits, setHits] = useState([]);
  const [value, setValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const location = useLocation();
  let history = useHistory();

  // mount/unmount
  useEffect(() => {
    setHits(props.hits);
    Emitter.on("QUERY_RESET", emptySearch);

    return () => {
      Emitter.removeEventListener("QUERY_RESET", emptySearch);
      if (input) removeEventListeners(input);
    };
  }, []);

  // location change
  useEffect(() => {
    emptySearch();
    // FIX: to prevent search to staying focused when changing page
    if (input) {
      input.disabled = true;
      input.disabled = false;
    }
  }, [location.pathname]);

  useEffect(() => {
    setHits(props.hits);
  }, [props.hits]);

  const inputProps = {
    placeholder: t("placeholder"),
    value,
    autoFocus: props.autoFocus,
    onChange: handleChange
  };

  return (
    <div className="Autosuggest" ref={searchContainerRef}>
      <Autosuggest
        focusInputOnSuggestionClick={false}
        getSectionSuggestions={getSectionSuggestions}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        multiSection
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionHighlighted={handleSuggestionHighlighted}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        onSuggestionSelected={handleSuggestionSelected}
        ref={storeInputReference}
        renderSectionTitle={renderSectionTitle}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={hits}
      />
      {value && (
        <Button
          color="link"
          className="text-black-50 react-autosuggest__clear-btn"
          onClick={() => {
            input && input.focus();
            emptySearch();
          }}
          onBlur={handleResetBtnBlur}
        >
          <FontAwesomeIcon
            className="text-muted"
            icon="times-circle"
            size="sm"
          />
        </Button>
      )}
    </div>
  );

  // FUNCTIONS

  function addEventListeners() {
    input.addEventListener("keypress", handleKeyPress);
    input.addEventListener("focus", handleSearchFocus);
    input.addEventListener("blur", handleSearchBlur);
  }

  function blurSearch() {
    Emitter.emit("SEARCH_BLUR");
    setOpen(false);
  }

  function emptySearch() {
    setValue("");
    setSearchValue("");
  }

  function getSectionSuggestions(section) {
    const { hits } = section;

    // clone hits and add index
    let newHits = hits.map(hit => ({ ...hit, index: section.index }));

    // remove duplicate entries for areas, which uses a string array
    // the other indices use a string property, and has a distinct setting,
    // so only unique items are returned.
    // Use the actual search value instead of the display value
    if (section.index === Algolia.areasIndex.value)
      newHits = removeDuplicateAreas(newHits, "areas", searchValue);

    // the results is narrowed down to numHits through our <Configure /> tag.
    // But because each hit can have multiple areas, and to prevent autocomplete
    // menu to have too many items, we also narrow down areas to numHits.
    return newHits.slice(0, props.numHits);
  }

  function getSuggestionValue(hit) {
    switch (hit.index) {
      case Algolia.citiesIndex.value:
        return hit.city;
      case Algolia.areasIndex.value:
        return hit.area;
      case Algolia.countriesIndex.value:
        return hit.country;
      default:
        return hit.name;
    }
  }

  function handleChange(event, { newValue, method }) {
    switch (method) {
      // we separate the display value and the entered search value
      // so that when filtering the areas, we compare it to the search value
      // and not the value being set when arrowing up and down the menu items
      case "type":
        setSearchValue(newValue);
        if (!newValue) setOpen(true);
        break;

      case "enter":
        setValue(hasKeyboardSelection ? newValue : value);
        break;

      case "escape":
        blurSearch();
        break;

      default:
        break;
    }

    if (value !== newValue) setValue(newValue);
  }

  function handleClubClick(event, slug) {
    event.preventDefault();
    const newLocation = GOLF_CLUB_ROUTE.url(slug);
    const replace = location.pathname === newLocation;
    navigate(newLocation, replace);
  }

  // This method is used to capture event when user presses enter
  // without having selected an item in the search results
  function handleKeyPress(e) {
    const key = e.which || e.keyCode;

    // make query if user presses enter button and no selection is made
    if (key === 13 && !hasKeyboardSelection) {
      if (value) handleSearchClick(e, "query", value);
      else handleSearchAllClick(e);
    }
  }

  function handleResetBtnBlur(e) {
    if (e.relatedTarget && e.relatedTarget !== input)
      Emitter.emit("SEARCH_BLUR");
  }

  function handleSearchAllClick(event) {
    event.preventDefault();

    const isSearchPage = routesMatch(
      location.pathname,
      SEARCH_ROUTE.path,
      false
    );
    const noGeoParams = !URLHelper.hasAnyOfParams(location, GEO_ATTRS);
    const replace = isSearchPage && noGeoParams;

    emptySearch();

    navigate(
      {
        pathname: SEARCH_ROUTE.url(),
        state: { external: true }
      },
      replace
    );
  }

  function handleSearchClick(event, param, value) {
    event.preventDefault();
    const replace = URLHelper.hasParam(location, param, value);
    navigate(
      {
        pathname: SEARCH_ROUTE.url(),
        search: `?${param}=${encodeURIComponent(value)}`,
        state: { external: true }
      },
      replace
    );
  }

  function handleSearchBlur(event) {
    const newTarget = event.relatedTarget;

    if (!newTarget) {
      setOpen(false);
      Emitter.emit("SEARCH_BLUR");
    }
    // If the new focus element is outside the search container
    else if (newTarget && !searchContainerRef.current.contains(newTarget)) {
      setOpen(false);
      Emitter.emit("SEARCH_BLUR");
    }
  }

  function handleSearchFocus() {
    setOpen(true);
    Emitter.emit("SEARCH_FOCUS");

    // Scroll element into view on focus
    if (input) {
      const { y } = input.getBoundingClientRect();
      const margin = -20;
      window.scrollTo(0, y + window.pageYOffset + margin);
    }

    const s = searchContainerRef;
    const clickOutside = event =>
      s && s.current && !s.current.contains(event.target);

    document.addEventListener(
      "mousedown",
      function clickOutsideListener(event) {
        if (clickOutside(event)) {
          setOpen(false);
          Emitter.emit("SEARCH_BLUR");
          document.removeEventListener("mousedown", clickOutsideListener, true);
        }
      },
      true
    );
  }

  function handleSuggestionsFetchRequested({ value }) {
    props.refine(value);
  }

  function handleSuggestionsClearRequested() {
    setHits([]);
  }

  function handleSuggestionHighlighted({ suggestion }) {
    // if there is a selected suggestion we have a selection
    hasKeyboardSelection = suggestion ? true : false;
  }

  function handleSuggestionSelected(event, { suggestion, method }) {
    switch (suggestion.index) {
      case Algolia.citiesIndex.value:
        handleSearchClick(event, "city", suggestion.city);
        break;
      case Algolia.areasIndex.value:
        handleSearchClick(event, "area", suggestion.area);
        break;
      case Algolia.countriesIndex.value:
        handleSearchClick(event, "country", suggestion.country);
        break;
      default:
        handleClubClick(event, suggestion.slug);
        break;
    }
  }

  function navigate(location, replace) {
    blurSearch();
    if (replace) history.replace(location);
    else history.push(location);
  }

  function removeDuplicateAreas(arr, prop, needle) {
    let unique = {
      values: [],
      items: []
    };

    arr.forEach(item => {
      if (item.hasOwnProperty(prop) && Array.isArray(item[prop])) {
        item[prop].forEach(value => {
          // if the query matches the current hit's area and is not included in the return array
          // we add it to the returned list of areas (rendered)
          if (
            MatchHelper.isMatch(needle, value) &&
            !unique.values.includes(value)
          ) {
            unique.values.push(value);
            // Because it's the same object for multiple hits, we need to clone it so we can set the area property
            // on it. otherwise we override it on the next iteration
            unique.items.push({ ...item, area: value });
          }
        });
      }
    });
    return unique.items;
  }

  function removeEventListeners() {
    input.removeEventListener("keypress", handleKeyPress);
    input.removeEventListener("focus", handleSearchFocus);
    input.removeEventListener("blur", handleSearchBlur);
  }

  function renderSectionTitle(section) {
    if (!section.hits.length) return null;

    switch (section.index) {
      case Algolia.citiesIndex.value:
        return t("cities");
      case Algolia.areasIndex.value:
        return t("areas");
      case Algolia.countriesIndex.value:
        return t("countries.countries");
      default:
        return t("golf_courses");
    }
  }

  function renderSuggestion(hit, search) {
    switch (hit.index) {
      case Algolia.citiesIndex.value:
        return (
          <CityHit
            hit={hit}
            onClick={e => handleSearchClick(e, "city", hit.city)}
          />
        );

      case Algolia.areasIndex.value:
        return (
          <AreaHit
            hit={hit}
            onClick={e => handleSearchClick(e, "area", hit.area)}
          />
        );

      case Algolia.countriesIndex.value:
        return (
          <CountryHit
            hit={hit}
            onClick={e => handleSearchClick(e, "country", hit.country)}
          />
        );

      default:
        return (
          <GolfCourseHit
            hit={hit}
            onClick={e => handleClubClick(e, hit.slug)}
          />
        );
    }
  }

  function renderSuggestionsContainer({ containerProps, children, query }) {
    let showNearMe = false;
    let showAllCourses = null;
    let showNoHits = null;

    if (input) {
      if (open && !query.length) {
        showNearMe = true;
        showAllCourses = true;
      }

      const noHits = hits.find(index => index.hits.length > 0) === undefined;
      if (open && noHits && query.length) {
        showNoHits = true;
      }
    }

    return (
      <div {...containerProps}>
        {showNearMe && (
          <NearMe
            onClick={e =>
              handleSearchClick(e, "sortBy", Algolia.rangeIndex.type)
            }
          />
        )}
        {showNoHits && <NoHits query={query} />}
        {showAllCourses && <AllCourses onClick={handleSearchAllClick} />}
        {children}
      </div>
    );
  }

  function storeInputReference(ref) {
    // since this function runs on each render we have to remove any
    // previous eventListeners on the input element, otherwise
    // we will get multiple triggers, causing strange behaviour
    if (input) removeEventListeners(input);

    // store input reference and re-add eventListeners
    if (ref) {
      input = ref.input;
      addEventListeners(input);
    }
  }
}

export default connectAutoComplete(AutoSuggestWrapper);
